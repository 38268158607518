import React from "react"
import { FaHome, FaCheck, FaRegSun, FaShopify, FaTicketAlt, FaProductHunt, FaSlidersH, FaUserTie, FaGithubSquare } from 'react-icons/fa'


const user = JSON.parse(sessionStorage.getItem('user'))

const navigationSuperAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "Opciones cliente",
    title: "Opciones cliente",
    type: "collapse",
    icon: <FaUserTie size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "crear-cliente",
        title: "gestión cliente",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente",
      },
      {
        id: "cliente producto",
        title: "cliente producto",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cliente-producto",
      },
    ]
  },
  {
    id: "Opciones Producto",
    title: "Opciones Producto",
    type: "collapse",
    icon: <FaProductHunt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Productos",
        title: "Productos",
        type: "item",
        icon: <FaShopify size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/products"
      },
      {
        id: "ProductsRequest",
        title: "Solicitud por producto",
        type: "item",
        icon: <FaProductHunt size={20} />,
        permissions: ["admin", "editor"],
        navLink: "/product-request"
      },
    ]
  },
  {
    id: "MiHojaVida",
    title: "Mi Hoja Vida",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/mi-hoja-vida"
  },

  {
    id: "tipificacion",
    title: "Tipificacion",
    type: "item",
    icon: <FaSlidersH size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/tipificacion"
  },
  {
    id: "gsistema",
    title: "Sistema",
    type: "collapse",
    icon: <FaRegSun size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "sistema1",
        title: "Usuarios",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/users"
      },
      {
        id: "roles",
        title: "Roles",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/roles"
      },
      {
        id: "sistema3",
        title: "Centro de costos",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/centro-costo"
      },
      {
        id: "sistema4",
        title: "Cargo",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/cargo"
      },
      {
        id: "sistema5",
        title: "Area",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/area"
      },
    ]
  },
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  },
  {
    id: "Agenda",
    title: "Agenda",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/agenda"
  }


];

const navigationUser = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "Listado",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
    ]
  }
];

const navigationShopping = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompra",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscomunicaciones",
      },
    ]
  }
];

const navigationComunicaciones = [
  {
    id: "tickets",
    title: "Tickets",
    type: "collapse",
    icon: <FaTicketAlt size={20} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "listado",
        title: "IT",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets",
      },
      {
        id: "listado2",
        title: "Compras",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/ticketscompras",
      },
      {
        id: "listado3",
        title: "Comunicaciones",
        type: "item",
        icon: <FaCheck size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/tickets-comunicaciones",
      },
    ]
  }
];


const navigationConfig =
  user?.profile === 2 ? navigationSuperAdmin :
    user?.profile === 3 ? navigationUser :
      user?.profile === 65 ? navigationShopping :
        user?.profile === 75 ? navigationComunicaciones :
          []

export default navigationConfig